import React from "react";
import { Link } from "gatsby";
export default function Home() {
  return (
    <div>
      You have reached nowhere!
      <p>
        please return to <Link to="/">Dashboard</Link>
      </p>
    </div>
  );
}
